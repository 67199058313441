(function () {
  'use strict';

  angular
    .module('module.sales')
    .factory('CustomItemService', ['$q', 'Notification', 'ApiService', 'UploadService', service]);

  function service($q, Notification, ApiService, UploadService) {
    return {
      createCustomItem,
      retrieveCustomItem,
      updateCustomItem,
      copy,
      createFromPartsShop,
      updateItemFromPartsShop,
    };

    /**
     * Create a custom item
     *
     * @param {string} quoteId
     * @param {object} customItem
     * @param {File} imageFile
     * @returns {$q}
     */
    function createCustomItem(quoteId, customItem, imageFile) {
      var url = '/quotes/' + quoteId + '/customItems';

      return uploadImage(imageFile).then(function (imageId) {
        customItem.image = imageId;

        return ApiService.post(url, customItem).then(
          function (response) {
            Notification.success('Added to Quote');

            return response.data;
          },
          function () {
            Notification.error('Failed to add to Quote');

            return $q.reject();
          },
        );
      });
    }

    /**
     * @param {File|null} imageFile
     * @returns {Promise}
     */
    function uploadImage(imageFile) {
      if (!imageFile) {
        return $q.when(null);
      }

      return UploadService.uploadImage(imageFile).then((file) => file.resourceId);
    }

    /**
     * Retrieve a custom item
     *
     * @param {string} quoteId
     * @param {string} customItemId
     * @returns {$q}
     */
    function retrieveCustomItem(quoteId, customItemId) {
      var url = '/customItems/' + customItemId;

      return ApiService.get(url).then(function (response) {
        return response.data;
      });
    }

    /**
     * Save a custom item
     *
     * @param {string} quoteId
     * @param {string} customItemId
     * @param {object} customItem
     * @param {File} imageFile
     * @returns {$q}
     */
    function updateCustomItem(quoteId, customItemId, customItem, imageFile) {
      var url = '/quotes/' + quoteId + '/customItems/' + customItemId;

      var payload = {
        location: customItem.location,
        jobType: customItem.jobType,
        costPrice: customItem.costPrice,
        sellPrice: customItem.sellPrice,
        notes: customItem.notes,
        quantity: customItem.quantity,
        includeInQuote: customItem.includeInQuote,
        supplierQuoteReference: customItem.supplierQuoteReference,
        supplier: customItem.supplier,
        name: customItem.name,
        quantityNotApplicable: customItem.quantityNotApplicable,
        pricingUnit: customItem.pricingUnit,
        discountable: customItem.discountable,
        multiple: customItem.multiple,
        dedicatedPageContract: customItem.dedicatedPageContract,
        dedicatedPageQuote: customItem.dedicatedPageQuote,
        dedicatedPageSurveyorReport: customItem.dedicatedPageSurveyorReport,
      };

      return uploadImage(imageFile).then(function (imageId) {
        payload.image = imageId ? imageId : customItem.imageId;

        return ApiService.put(url, payload).then(
          function (response) {
            Notification.success('Saved');

            return response.data;
          },
          function () {
            Notification.error('Failed to save');

            return $q.reject();
          },
        );
      });
    }

    /**
     * Copy a custom item
     *
     * @param {object} quote
     * @param {object} item
     * @returns {$q}
     */
    function copy(quote, item) {
      return ApiService.post('/quotes/' + quote.id + '/customItems/' + item.id + '/copy').then(
        function (response) {
          Notification.success('Copied successfully');

          return response.data;
        },
        function () {
          Notification.error('Failed to copy');

          return $q.reject(null);
        },
      );
    }

    /**
     * @param {string} quoteId
     * @param {string} templateId
     * @param {Object} item
     * @returns {Promise}
     */
    function createFromPartsShop(quoteId, templateId, item) {
      const url = `/quotes/${quoteId}/customItems/fromTemplate/${templateId}`;

      const payload = {
        location: item.location,
        jobType: item.jobType,
        quantity: item.quantity,
        multiple: item.multiple,
        includeInQuote: item.includeInQuote,
        dedicatedPageContract: item.dedicatedPageContract,
        dedicatedPageQuote: item.dedicatedPageQuote,
        dedicatedPageSurveyorReport: item.dedicatedPageSurveyorReport,
        notes: item.notes,
      };

      return ApiService.post(url, payload).then(
        (response) => {
          Notification.success('Added to Quote');

          return response.data;
        },
        () => {
          Notification.error('Failed to add to Quote');

          return $q.reject();
        },
      );
    }

    /**
     * @param {string} quoteId
     * @param {string} itemId
     * @param {Object} item
     * @returns {Promise}
     */
    function updateItemFromPartsShop(quoteId, itemId, item) {
      const url = `/quotes/${quoteId}/customItems/fromTemplate/${itemId}`;

      const payload = {
        location: item.location,
        jobType: item.jobType,
        quantity: item.quantity,
        multiple: item.multiple,
        includeInQuote: item.includeInQuote,
        dedicatedPageContract: item.dedicatedPageContract,
        dedicatedPageQuote: item.dedicatedPageQuote,
        dedicatedPageSurveyorReport: item.dedicatedPageSurveyorReport,
        notes: item.notes,
      };

      return ApiService.put(url, payload).then(
        (response) => {
          Notification.success('Saved');

          return response.data;
        },
        () => {
          Notification.error('Failed to save');

          return $q.reject();
        },
      );
    }
  }
})();
