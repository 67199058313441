(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.pending.ownerUpdate', [
      '$state',
      '$window',
      'PendingRetailerService',
      'dataPendingRetailer',
      controller,
    ]);

  function controller($state, $window, PendingRetailerService, dataPendingRetailer) {
    /* jshint validthis: true */
    var vm = this;

    vm.retailer = dataPendingRetailer;

    vm.hasLinkedRetailer = null !== vm.retailer.linkedRetailer;
    vm.allowNew = !vm.hasLinkedRetailer;
    vm.suggestedRetailers = vm.hasLinkedRetailer
      ? [vm.retailer.linkedRetailer]
      : vm.retailer.suggestedRetailers;

    vm.linkRetailer = linkRetailer;
    vm.newRetailer = newRetailer;
    vm.rejectRetailer = rejectRetailer;

    /**
     * Link the pending retailer to an actual retailer.
     *
     * @param {object} linkToRetailer
     */
    function linkRetailer(linkToRetailer) {
      if (
        $window.confirm(
          'Link ' + linkToRetailer.name + ' to ' + vm.retailer.invitingManufacturer.name + '?',
        )
      ) {
        PendingRetailerService.linkRetailer(vm.retailer, linkToRetailer).then(function () {
          $state.go('sales.retailers.pending.ownerList');
        });
      }
    }

    /**
     * Create a new retailer.
     */
    function newRetailer() {
      var prompt =
        'Create new retailer ' +
        vm.retailer.companyName +
        ' for ' +
        vm.retailer.invitingManufacturer.name +
        '?';

      if ($window.confirm(prompt)) {
        PendingRetailerService.newRetailer(vm.retailer).then(function () {
          $state.go('sales.retailers.pending.ownerList');
        });
      }
    }

    function rejectRetailer() {
      var prompt = 'Are you sure you want to reject this retailer?';

      if ($window.confirm(prompt)) {
        PendingRetailerService.reject(vm.retailer).then(function () {
          $state.go('sales.retailers.pending.ownerList');
        });
      }
    }
  }
})();
