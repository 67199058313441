(function () {

'use strict';

angular.module("tommyApp")
.constant("APP_ENV", "dev")
.constant("BUGSNAG_API_KEY", "5bdad9dda689573e26e330bafb2d8a53")
.constant("FRESHCHAT_TOKEN", "a1182dfe-9601-47ea-a06b-7d398c07be7f")
.constant("STRIPE_PUBLIC_KEY", "pk_test_dSqwBh7tyiCpVahzJUs21KEs")
.constant("PORTAL_VERSION", {"sha":"837eae2269fad816714737d73246b5bbbb9528b9","tag":"v1.45.10"});

})();
