(function () {
  'use strict';

  angular
    .module('module.settings.advanced')
    .controller('controller.settings.advanced.country', [
      '$scope',
      'CurrencyApiService',
      'dataSettings',
      controller,
    ]);

  function controller($scope, CurrencyApiService, dataSettings) {
    /* jshint validthis: true */
    var vm = this;

    vm.update = update;
    vm.settings = dataSettings.settings.currency;
    vm.saving = false;

    function update() {
      vm.saving = true;
      $scope.form.$setPristine();

      CurrencyApiService.update(vm.settings).finally(function () {
        vm.saving = false;
      });
    }
  }
})();
