(function () {
  'use strict';

  angular
    .module('module.settings.company')
    .controller('controller.settings.company.contactDetails', [
      'SettingsService',
      'AuthenticationService',
      'dataSettings',
      controller,
    ]);

  function controller(SettingsService, AuthenticationService, dataSettings) {
    /* jshint validthis: true */
    var vm = this;

    vm.company = dataSettings;
    vm.isRetailer = AuthenticationService.isRetailer();
    vm.errors = {};
    vm.isSubmitting = false;

    vm.update = update;
    vm.enableSubmit = enableSubmit;

    function update() {
      if (vm.isSubmitting) {
        return;
      }

      vm.isSubmitting = true;

      SettingsService.updateSettings(vm.company)
        .then(function (company) {
          vm.company = company;
        })
        .finally(function () {
          vm.isSubmitting = false;
        });
    }

    function enableSubmit() {
      vm.formHasChanged = true;
      vm.isSubmitting = false;
    }
  }
})();
