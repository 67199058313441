(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.retailers.createPending', [
      '$scope',
      '$state',
      'PendingRetailerService',
      'dataPaymentTerms',
      controller,
    ]);

  function controller($scope, $state, PendingRetailerService, dataPaymentTerms) {
    /* jshint validthis: true */
    var vm = this;

    vm.create = create;

    vm.paymentTerms = dataPaymentTerms;
    vm.pendingRetailer = {};

    function create() {
      PendingRetailerService.create(vm.pendingRetailer).then(function () {
        $state.go('sales.retailers.list');
      });
    }
  }
})();
