(function () {
  'use strict';

  angular
    .module('module.sales')
    .controller('controller.sales.quotes.customItem', [
      '$state',
      '$scope',
      'CustomItemService',
      'SettingsService',
      'CurrencyService',
      'PRICING_UNIT',
      'PRICING_UNIT_SUFFIX',
      'dataCustomItem',
      'dataQuote',
      controller,
    ]);

  function controller(
    $state,
    $scope,
    CustomItemService,
    SettingsService,
    CurrencyService,
    PRICING_UNIT,
    PRICING_UNIT_SUFFIX,
    dataCustomItem,
    dataQuote,
  ) {
    /* jshint validthis: true */
    const $ctrl = this;

    $ctrl.updateCheckboxes = function () {
      if (!$ctrl.item.includeInQuote) {
        $ctrl.item.dedicatedPageQuote =
          $ctrl.item.dedicatedPageSurveyorReport =
          $ctrl.item.dedicatedPageContract =
            false;
      } else {
        $ctrl.item.dedicatedPageQuote =
          $ctrl.item.dedicatedPageSurveyorReport =
          $ctrl.item.dedicatedPageContract =
            true;
      }
    };

    if (dataCustomItem && dataCustomItem.metadata.fromTemplate) {
      $state.go('errors.404');

      return;
    }

    $ctrl.save = save;
    $ctrl.resetImage = resetImage;
    $ctrl.setQuantity = setQuantity;
    $ctrl.changePricingUnit = changePricingUnit;

    $ctrl.saving = false;
    $ctrl.pricingUnits = [
      {name: 'Each', value: PRICING_UNIT.EACH},
      {name: 'Per metre', value: PRICING_UNIT.PER_METRE},
      {name: 'Per square metre', value: PRICING_UNIT.PER_SQUARE_METRE},
      {name: 'Per litre', value: PRICING_UNIT.PER_LITRE},
      {name: 'Per kilo', value: PRICING_UNIT.PER_KILO},
    ];

    $ctrl.pricingUnitSuffix = PRICING_UNIT_SUFFIX;

    // Note: this is for the breadcrumbs. Don't use $scope for anything else
    $ctrl.quote = dataQuote;
    $scope.quote = dataQuote;
    $scope.customer = dataQuote.metadata.customer;
    $scope.customerfullname = dataQuote.metadata.customer.name.fullName;
    $scope.customersampleId = dataQuote.metadata.customer.id;
    $scope.hashLocation = 'quotes';
    $scope.customer.metadata = {
      name: $scope.customer.name,
    };

    $ctrl.image = null;
    $ctrl.currency = CurrencyService.getSettings();

    $ctrl.mode = 'update';
    if (dataCustomItem) {
      $ctrl.item = dataCustomItem.metadata;
    } else {
      $ctrl.mode = 'create';
      $ctrl.item = {
        quantity: 1,
        multiple: 1,
        costPrice: 0,
        sellPrice: 0,
        includeInQuote: true,
        dedicatedPageQuote: true,
        dedicatedPageSurveyorReport: true,
        dedicatedPageContract: true,
        pricingUnit: PRICING_UNIT.EACH,
        jobType: null,
        discountable: true,
        location: '',
      };
    }

    changePricingUnit();

    $ctrl.readOnly = dataQuote.metadata.status !== 'draft';

    $ctrl.loadingSettings = true;
    SettingsService.getSettings().then((data) => {
      $ctrl.loadingSettings = false;
      $ctrl.showIncludeInQuote = !data.settings.showItemPricing;
    });

    function setQuantity(amount) {
      if (amount === 'N/A') {
        $ctrl.item.quantity = 1;
        $ctrl.item.quantityNotApplicable = true;
      } else {
        $ctrl.item.quantity = amount;
        $ctrl.item.quantityNotApplicable = false;
      }
    }

    function changePricingUnit() {
      if ($ctrl.item.pricingUnit !== PRICING_UNIT.EACH) {
        setQuantity(1);
        $ctrl.showQuantity = false;
      } else {
        $ctrl.item.multiple = 1;
        $ctrl.showQuantity = true;
      }
    }

    function save() {
      $ctrl.saving = true;
      const action =
        $ctrl.mode === 'update'
          ? CustomItemService.updateCustomItem(
              dataQuote.id,
              dataCustomItem.id,
              $ctrl.item,
              $ctrl.image,
            )
          : CustomItemService.createCustomItem(dataQuote.id, $ctrl.item, $ctrl.image);

      action.then(
        () => $state.go('sales.quotes.retrieve', {quoteId: dataQuote.id}),
        () => ($ctrl.saving = false),
      );
    }

    function resetImage() {
      $ctrl.image = null;
      $ctrl.item.imageId = null;
    }
  }
})();
