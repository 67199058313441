(function () {
  'use strict';

  angular.module('module.sales').component('ttPendingCompanyDetails', {
    templateUrl: 'templates/sales.retailers.pending.companyDetails.component.template.html',
    bindings: {
      company: '<',
      showLink: '<?',
      onLink: '&?',
    },
  });
})();
