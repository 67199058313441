(function () {
  'use strict';

  angular.module('module.sales').component('ttPendingSearchRetailers', {
    templateUrl: 'templates/sales.retailers.pending.searchRetailers.component.template.html',
    bindings: {
      onLink: '<',
    },
    controller: ['RetailersService', controller],
  });

  function controller(RetailersService) {
    /* jshint validthis: true */
    var $ctrl = this;

    $ctrl.changeSearchTerm = changeSearchTerm;

    $ctrl.retailers = [];
    $ctrl.searchTerm = '';

    function changeSearchTerm() {
      if ($ctrl.searchTerm.length >= 3) {
        RetailersService.listRetailers(1, 5000, $ctrl.searchTerm).then(processRetailers);
      }
    }

    /**
     * Reformat retailers for companyDetails component, excluding internal retailers.
     */
    function processRetailers(list) {
      var retailers = [];

      angular.forEach(list.retailers, function (retailer) {
        if (retailer.metadata.internalRetailer || retailer.metadata.archived) {
          return;
        }

        retailers.push({
          id: retailer.id,
          name: retailer.metadata.name,
          address: retailer.metadata.address,
          contactNumber: retailer.metadata.contactNumber,
          email: retailer.metadata.email,
        });
      });

      $ctrl.retailers = retailers;
    }
  }
})();
