(function () {
  'use strict';

  angular.module('module.sales').controller('controller.sales.customers.list', [
    '$rootScope',
    '$scope',
    '$filter',
    'CustomersService',
    'CustomerSegmentationService',
    'BreadcrumbsService',
    'dataCustomers',
    'dataUsers',
    function (
      $rootScope,
      $scope,
      $filter,
      CustomersService,
      SegService,
      BreadcrumbsService,
      dataCustomers,
      dataUsers,
    ) {
      $rootScope.bodyClass = 'customer__list';
      $scope.customers = dataCustomers.customers;
      $scope.pagination = {
        totalPages: dataCustomers.meta.totalPages,
        currentPage: 1,
      };
      $scope.customerSearch = '';
      $scope.segmentation = false;
      $scope.search = search;
      $scope.changePage = changePage;
      $scope.showStats = false;

      BreadcrumbsService.hideBreadcrumbs();

      // only init segmentation if segmenting
      SegService.getSegmenting().then(function (segment) {
        if (segment) {
          initSegmentation();
        }
      });

      /**
       * Set up segmentation functionality
       */
      function initSegmentation() {
        $scope.changeUser = changeUser;
        $scope.userSelection = [];
        $scope.userSelection.push(dataUsers[0]); // TODO: TEMP, restrict to own user
        $scope.selectedUser = $scope.userSelection[0];

        // manager users can see all customers as well as own, should default to this
        SegService.isManagerUser().then(function (isManagerUser) {
          if (isManagerUser) {
            $scope.userSelection.push({id: 'all', name: 'All Customers'});
            $scope.selectedUser = $scope.userSelection[1];
          }

          updateBreadcrumbLabel();
          $scope.segmentation = true;
        });
      }

      /**
       * Get list of customers applying current user and search
       * selectedUser not set when not segmenting so just pass null
       */
      function updateCustomers(page) {
        var userId = typeof $scope.selectedUser === 'undefined' ? null : $scope.selectedUser.id;

        page = page || 1;

        CustomersService.listCustomers(userId, $scope.customerSearch, page).then(
          function (customers) {
            $scope.pagination = {
              totalPages: customers.meta.totalPages,
              currentPage: page,
            };
            $scope.customers = customers.customers;
          },
        );
      }

      /**
       * Change selected segmentation user
       *
       * @param user
       */
      function changeUser(user) {
        $scope.selectedUser = user;
        updateBreadcrumbLabel();
        updateCustomers();
      }

      /**
       * Set the breadcrumb label to the selected user
       */
      function updateBreadcrumbLabel() {
        $scope.breadcrumbLabel = $filter('userSelectName')($scope.selectedUser);
      }

      /**
       * Run search
       */
      function search() {
        updateCustomers();
      }

      function changePage(newPage) {
        updateCustomers(newPage);
      }
    },
  ]);
})();
