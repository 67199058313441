(function () {
  'use strict';

  angular.module('module.sales').constant('pricingModes', {
    none: 'none',
    fixed: 'fixed',
    perProduct: 'perProduct',
  });

  angular.module('module.sales').config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider

        .state('sales.retailers', {
          abstract: true,
          ncyBreadcrumb: {
            skip: true,
          },
        })

        .state('sales.retailers.list', {
          url: '/retailers',
          views: {
            'content@': {
              controller: 'controller.sales.retailers.list',
              controllerAs: 'retailerVm',
              templateUrl: 'templates/sales.retailers.list.template.html',
              resolve: {
                dataRetailers: [
                  'RetailersService',
                  function (RetailersService) {
                    return RetailersService.listRetailers(1);
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            label: 'Retailers',
          },
          data: {
            meta: {
              titleSuffix: '| Retailers',
            },
          },
        })

        .state('sales.retailers.create', {
          url: '/retailers/new',
          views: {
            'content@': {
              controller: 'controller.sales.retailers.createPending',
              controllerAs: 'retailerVm',
              templateUrl: 'templates/sales.retailers.createPending.template.html',
              resolve: {
                dataPaymentTerms: [
                  'PaymentTermsService',
                  function (PaymentTermsService) {
                    return PaymentTermsService.paymentTermsList();
                  },
                ],
              },
            },
          },
          ncyBreadcrumb: {
            parent: 'sales.retailers.list',
            label: 'New Retailer',
          },
          data: {
            meta: {
              titleSuffix: '| New Retailer',
            },
          },
        });

      var retailerNav = [
        {id: 'sales.retailers', name: 'Details', state: 'sales.retailers.update.details'},
        {id: 'sales.retailers', name: 'Account', state: 'sales.retailers.update.account'},
        {id: 'sales.retailers', name: 'Marketing', state: 'sales.retailers.update.marketing'},
        {
          id: 'sales.retailers',
          name: 'Products and Pricing',
          state: 'sales.retailers.update.products',
        },
      ];

      $stateProvider.state('sales.retailers.update', {
        url: '/retailers/:retailerId',
        abstract: true,
        views: {
          'content@': {
            controller: [
              '$scope',
              'NavigationService',
              function ($scope, NavigationService) {
                $scope.navigation = retailerNav;
              },
            ],
            templateUrl: 'templates/settings.template.html',
          },
        },
        ncyBreadcrumb: {
          parent: 'sales.retailers.list',
          label: '{{ $ctrl.retailer.metadata.name }}',
        },
      });

      $stateProvider.state('sales.retailers.update.details', {
        url: '',
        controller: 'controller.sales.retailers.update.details',
        controllerAs: '$ctrl',
        templateUrl: 'templates/sales.retailers.update.details.template.html',
        resolve: {
          dataRetailer: [
            '$stateParams',
            'RetailersService',
            function ($stateParams, RetailersService) {
              return RetailersService.retrieveRetailer($stateParams.retailerId);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Details',
        },
        data: {
          meta: {
            titleSuffix: '| Retailer Details',
          },
        },
      });

      $stateProvider.state('sales.retailers.update.account', {
        url: '/account',
        controller: 'controller.sales.retailers.update.account',
        controllerAs: '$ctrl',
        templateUrl: 'templates/sales.retailers.update.account.template.html',
        resolve: {
          dataRetailer: [
            '$stateParams',
            'RetailersService',
            function ($stateParams, RetailersService) {
              return RetailersService.retrieveRetailer($stateParams.retailerId);
            },
          ],
          dataPaymentTerms: [
            'PaymentTermsService',
            function (PaymentTermsService) {
              return PaymentTermsService.paymentTermsList();
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Account',
        },
        data: {
          meta: {
            titleSuffix: '| Retailer Account',
          },
        },
      });

      $stateProvider.state('sales.retailers.update.marketing', {
        url: '/marketing',
        controller: 'controller.sales.retailers.update.marketing',
        controllerAs: '$ctrl',
        templateUrl: 'templates/sales.retailers.update.marketing.template.html',
        resolve: {
          dataRetailer: [
            '$stateParams',
            'RetailersService',
            function ($stateParams, RetailersService) {
              return RetailersService.retrieveRetailer($stateParams.retailerId);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Marketing',
        },
        data: {
          meta: {
            titleSuffix: '| Retailer Marketing',
          },
        },
      });

      $stateProvider.state('sales.retailers.update.products', {
        url: '/products',
        controller: 'controller.sales.retailers.update.products',
        controllerAs: '$ctrl',
        templateUrl: 'templates/sales.retailers.update.products.template.html',
        resolve: {
          dataRetailer: [
            '$stateParams',
            'RetailersService',
            function ($stateParams, RetailersService) {
              return RetailersService.retrieveRetailer($stateParams.retailerId);
            },
          ],
        },
        ncyBreadcrumb: {
          label: 'Products and Pricing',
        },
        data: {
          meta: {
            titleSuffix: '| Retailer Products and Pricing',
          },
        },
      });
    },
  ]);
})();
